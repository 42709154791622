<template>
    <div class="user-info">
<!--        <div class="navbar-wrapper">-->
<!--            <Navbar />-->
<!--        </div>-->

        <div class="user-container">
            <div class="main-content">
                <div class="info-card">
                    <div class="info-header">
                        <h2>个人信息</h2>
                    </div>
                    <el-row :gutter="20" class="info-content">
                        <el-col :span="12">
                            <div class="info-item"><strong>手机号：</strong>{{ phone }}</div>
                        </el-col>
                        <el-col :span="12">
                            <div class="info-item"><strong>余额：</strong>{{ balance }}词</div>
                        </el-col>
                    </el-row>
                </div>

                <div class="history-card">
                    <div class="history-header">
                        <h2>降重历史</h2>
                    </div>
                    <div class="table-wrapper">
                        <el-table :data="weightLossHistory" border style="width: 100% !important">
                            <el-table-column label="输入内容">
                                <template #default="scope">
                                    <a
                                        class="details-link"
                                        @click="showDetails(scope.row.input, '输入内容')"
                                        >点击查看</a
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column prop="inputNumber" label="输入字数"></el-table-column>
                            <el-table-column label="输出内容">
                                <template #default="scope">
                                    <a
                                        class="details-link"
                                        @click="showDetails(scope.row.showContent, '输出内容')"
                                        >点击查看</a
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column prop="showNumber" label="输出字数"></el-table-column>
                            <el-table-column prop="balance" label="余额"></el-table-column>
                            <el-table-column prop="score" label="得分"></el-table-column>
                            <el-table-column
                                prop="createdAt"
                                label="生成时间"
                                :formatter="dateTimeFormatterUTC"
                            ></el-table-column>
                            <el-table-column label="主页查看详情">
                                <template #default="scope">
                                    <!-- <router-link :to="{ name: 'HomePage', params: { id: scope.row.id } }">
                                查看详情
                            </router-link> -->
                                    <a
                                        class="details-link"
                                        @click="gotoHomepage(scope.row.id, '查看详情')"
                                        >点击查看</a
                                    >
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                    />
                </div>

                <DetailsWords
                    v-model:visible="dialogVisible"
                    :title="dialogTitle"
                    :content="dialogContent"
                />
            </div>

        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Navbar from '@/components/Navbar.vue'
import DetailsWords from '@/components/DetailsWords.vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'
import { formatTimeToMinuteUTC } from '@/utils/formatDateTime.js'
import Sidebar from '@/components/Sidebar.vue'
import store from '@/stores/store.js'

const phone = ref()
const balance = ref()
const router = useRouter()

async function fetchDataUserInfo() {
    const requestParams = {}
    try {
        const response = await apiService.listUserInfo(requestParams)

        phone.value = response.data.userInfo.phone
        balance.value = response.data.userInfo.balance
    } catch (error) {
        // console.log(error)

        if (error.response?.data?.code === 401 || error.response?.data?.business_code === 10001) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

// 当页码改变时的处理函数
const currentPage = ref(1)
const pageSize = ref(5)
function handleCurrentChange(newPage) {
    currentPage.value = newPage
    fetchDataWeightLossHistoryInfo() // 重新获取数据
}

// 当每页显示数量改变时的处理函数
function handleSizeChange(newSize) {
    pageSize.value = newSize
    fetchDataWeightLossHistoryInfo() // 重新获取数据
}

const weightLossHistory = ref([])
const totalCount = ref(0)
async function fetchDataWeightLossHistoryInfo() {
    const requestParams = {
        pageInfo: {
            pageSize: pageSize.value,
            page: currentPage.value
        }
    }
    try {
        const response = await apiService.listWeightLossHistory(requestParams)
        // console.log(response)
        weightLossHistory.value = response.data.weightLossContent
        totalCount.value = Number(response.data.totalCount)
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

const dialogVisible = ref(false)
const dialogTitle = ref('')
const dialogContent = ref('')
const showDetails = (content, title) => {
    dialogContent.value = content
    dialogTitle.value = title
    dialogVisible.value = true
}

const gotoHomepage = (contentId) => {
    store.commit('setContentId', contentId)
    router.push('/').then(() => {
        window.location.reload()
    })
}

// // 当排序改变时的处理函数
// const sortDesc = ref(false);
// const sortField = ref("vipEndTime");
// function handleSortChange({ prop, order }) {
//   sortField.value = prop;
//   sortDesc.value = order === "descending";
//   fetchDataWeightLossHistoryInfo(); // 重新获取数据
// }

onMounted(() => {
    fetchDataUserInfo()
    fetchDataWeightLossHistoryInfo()
})

const dateTimeFormatterUTC = (_row, _column, cellValue) => {
    return formatTimeToMinuteUTC(cellValue)
}
</script>

<style>
.user-info {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    flex: 1;
    flex-direction: column;
    align-items: center;
    position: relative;

    .navbar-wrapper {
        width: 100% !important;
        top: 0;
        z-index: 1000; /* 确保导航栏在顶层 */
    }

    .user-container {
        position: relative;
        display: flex;
        flex-direction: row; /* 修改为列方向布局 */
        width: 100% !important;
        height: 80% !important;
        margin-top: 5%;

        .slider-container {
            margin-left: auto;
        }

        .main-content {
            position: relative;
            margin: 0 5% 0 10%;
            width: 80% !important;
            height: 100% !important;
            display: flex;
            flex-direction: column; /* 修改为列方向布局 */
            /*
            border: 1px solid #002f5a;
            box-shadow: 0 0 10px 2px #002f5a;
            align-items: center;
            padding: 2% 2% 1% 2%;
            border-radius: 15px;
            */

            .info-card,
            .history-card {
                width: 90% !important;

                /* 定义一个带有凹陷三维效果边框的类 */
                border: 1px solid #002f5a; /* 设置边框，使阴影效果更明显 */
                padding: 16px; /* 为内容添加一些内边距 */
                background-color: #fff; /* 设置背景颜色，如果需要的话 */
                border-radius: 8px; /* 可选，添加圆角使边框更柔和 */

                /* 创建凹陷的三维效果，使用 inset 关键字 */
                box-shadow:
                    inset 3px 3px 5px 002f5a (0, 0, 0, 0.2),
                    /* 向右下方偏移3像素，模糊半径为5像素，当前色半透明黑色，从内部实施 */ inset -3px -3px
                        5px rgba(255, 255, 255, 0.8); /* 向左上方偏移3像素，模糊半径为5像素，颜色为半透明白色，从内部实施 */
            }
            .info-content {
                .info-item {
                    font-size: 16px;
                }
            }

            .history-card {
                margin-top: 5%;
            }

            .info-header,
            .history-header {
                margin-bottom: 20px;
                text-align: center;
            }

            .info-content {
                display: flex;
            }
            .table-wrapper {
                overflow-x: auto; /* 启用水平滚动条 */
                -webkit-overflow-scrolling: touch; /* 移动端惯性滚动优化 */
            }
        }
    }
}

.details-link {
    color: #1e90ff; /* 你可以根据需求选择其他颜色 */
    text-decoration: underline; /* 添加下划线使其更具点击性 */
}
.details-link:hover {
    color: #104e8b; /* 悬停时颜色更深一些 */
    cursor: pointer; /* 鼠标悬停时显示手形光标 */
}
</style>
