<template>
    <div class="register">
        <!--        <div class="navbar-wrapper">-->
        <!--            <Navbar />-->
        <!--        </div>-->
        <div class="register-container">
            <div class="register-main">
                <h1 class="title">注册</h1>

                <div class="register-login-div">
                    <span>
                        已有账号?
                        <RouterLink class="login-link" to="/login">登录</RouterLink>
                    </span>
                </div>

                <div class="register-pwd">
                    <el-input v-model="register.phone" style="width: 350px; height: 40px;">
                        <template v-slot:prepend>
                            <span class="input-prepend">手机号</span>
                        </template>
                    </el-input>
                    <el-input v-model="register.password" show-password
                              style="width: 350px; height: 40px;" type="password">
                        <template v-slot:prepend>
                            <span class="input-prepend">新密码</span>
                        </template>
                    </el-input>
                    <el-input v-model="register.confirmPassword" show-password
                              style="width: 350px; height: 40px;" type="password">
                        <template v-slot:prepend>
                            <span class="input-prepend">确认密码</span>
                        </template>
                    </el-input>
                    <el-input v-model="register.verificationCode" style="width: 350px; height: 40px;">
                        <template v-slot:prepend>
                            <span class="input-prepend">验证码</span>
                        </template>
                        <template v-slot:suffix>
                            <el-button :disabled="!canGetCode" class="get-verify-code-btn" link
                                       @click="getVerificationCode">
                                {{ buttonText }}
                            </el-button>
                        </template>
                    </el-input>

                </div>
                <div>
                    <el-button class="register-pwd-confirm-button" style="width: 150px" @click="registerButton">
                        注册
                    </el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'

const register = reactive({
    phone: '',
    password: '',
    confirmPassword: '',
    verificationCode: ''
})

// 验证码时间
const canGetCode = ref(true)
const buttonText = ref('获取验证码')
let timer = null
const getVerificationCode = async () => {
    if (timer) return

    canGetCode.value = false
    let countdown = 60
    buttonText.value = `${countdown}秒`

    timer = setInterval(() => {
        countdown -= 1
        buttonText.value = `${countdown}秒`
        if (countdown <= 0) {
            clearInterval(timer)
            timer = null
            canGetCode.value = true
            buttonText.value = '获取验证码'
        }
    }, 1000)

    // console.log('发送验证码到手机')
    const requestParams = {
        phone: register.phone,
        type: 1
    }
    try {
        await apiService.generateVerifyCode(requestParams)
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

// 注册
const router = useRouter()
const registerButton = async () => {
    // 校验 1. 确认密码
    // 校验确认密码

    if (register.password !== register.confirmPassword) {
        alertService.showError('两次密码不一致')
        return
    }
    // 参数
    const requestParams = {
        phone: register.phone,
        password: register.password,
        verifyCode: register.verificationCode
    }
    if (register.password < 6) {
        alertService.showError('密码长度不能小于6位')
        return
    }
    try {
        await apiService.registerByPhone(requestParams)
        alertService.showSuccess('账号注册成功')
        // 跳转登录界面
        router.push('/login').then(() => {
            window.location.reload()
        })
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}
</script>

<style>
.register {
    display: flex;
    width: max(100%, 800px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max(100%, 600px);


    .register-container {
        width: 600px;
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        align-items: center;
        border-radius: 15px;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        padding: 60px 30px;
        background-color: #fff;


        .register-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .register-pwd {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .input-prepend {
                    display: inline-block;
                    width: 80px; /* 根据最长的前缀文字调整宽度 */
                    text-align: center;
                }

                .get-verify-code-btn {
                    color: #002f5a;
                    cursor: pointer;
                }
            }

            .register-pwd-confirm-button {
                margin-top: 20px;
                background: #002f5a;
                color: #fff;
                cursor: pointer;

            }
        }

    }
}


</style>
