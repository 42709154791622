<template>

    <div class="login">
        <div class="login-container">
            <div class="login-main">
                <h2 class="title" style="margin-top: 0">登录</h2>
                <div class="login-pwd">
                    <el-input v-model="login.phone" style="width: 350px; height: 40px;">
                        <template v-slot:prepend>
                            帐号
                        </template>
                    </el-input>

                    <el-input v-model="login.password" show-password style="width:350px; height: 40px"
                              type="password">
                        <template v-slot:prepend>
                            密码
                        </template>
                        <template v-slot:suffix>
                            <p class="forget-pwd" @click="gotoForgetPwd">忘记密码？</p>
                        </template>
                    </el-input>
                </div>
                <div class="login-select-btn">
                    <el-button class="register-btn" style="width: 150px" @click="gotoRegister">注册</el-button>
                    <el-button class="login-btn" style="width: 150px" @click="loginButton">登录</el-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { reactive } from 'vue'
import { alertService } from '@/utils/alertService.js'
import { apiService } from '@/api/Api.js'
import { useRouter } from 'vue-router'

const login = reactive({
    phone: '',
    password: ''
})
const router = useRouter()
const loginButton = async () => {
    const requestParams = {
        phone: login.phone,
        password: login.password
    }
    try {
        const response = await apiService.login(requestParams)
        // console.log(response)
        // 检查响应状态码和内容
        if (response.status === 200 && response.data.userInfo.token) {
            // 存储JWT到localStorage
            localStorage.setItem('token', response.data.userInfo.token)
            // 跳转到主页或其他指定页面
            router.push('/').then(() => {
                window.location.reload()
            })
        } else {
            alertService.showError('用户名或密码错误')
        }
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

const gotoForgetPwd = () => {
    router.push('/retrieve-password').then(() => {
        window.location.reload()
    })
}

const gotoRegister = () => {
    router.push('/register').then(() => {
        window.location.reload()
    })
}
</script>

<style lang="scss">
.login {
    display: flex;
    width: max(100%, 800px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max(100%, 400px);

    .login-container {
        width: 400px;
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        padding: 60px 30px;
        background-color: #fff;

        .login-main {
            .login-pwd {
                display: flex;
                flex-direction: column;

                .forget-pwd {
                    color: #002f5a;
                    cursor: pointer;

                    :hover {
                        color: #1a8cff;
                    }
                }
            }

            .login-select-btn {
                width: 100%;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .login-btn {
                    background: #002f5a;
                    color: #fff;
                }
            }
        }

    }


}

.el-input el-input__wapper el-input__suffix el-input__suffix-inner forget-pwd:hover {
    color: #fff;
}
</style>
