<template>
    <div class="nav-container">
        <div class="head-nav">
            <div class="logo">
                <img alt="ZeroAI Logo" class="logo-link" height="40px" src="../../public/favicon.jpg"
                     width="80px" @click="gotoHome" />
            </div>
            <ul class="head-nav-list">
                <li class="head-nav-detail" @click.prevent="gotoHome">降AI</li>
                <li class="head-nav-detail" @click.prevent="gotoRecharge">充值</li>
                <li class="head-nav-detail" @click.prevent="showRedeemDialog">兑换码</li>
            </ul>
        </div>
        <div class="select-login">
            <el-button v-show="!isLoggedIn" class="select-login-btn" round @click="login">注册/登录</el-button>

            <div>
                <el-popover placement="bottom-end" trigger="click" width="120">
                    <template #reference>
                        <el-avatar v-show="isLoggedIn" class="login-avatar-icon" size="30">
                            <el-icon style="font-size: 25px">
                                <UserFilled />
                            </el-icon>
                        </el-avatar>
                    </template>
                    <div class="login-list">
                        <div class="login-detail" @click="gotoUser">账户信息</div>
                        <div class="login-detail" @click="gotoUpdatePassword">修改密码</div>
                        <div class="login-detail" @click="logout">退出登录</div>
                    </div>
                </el-popover>
            </div>

        </div>

        <!--        <div class="right-section">-->
        <!--            <div class="purchase-container" @mouseout="hideDropdown" @mouseover="showDropdown">-->
        <!--                &lt;!&ndash;                <a class="purchase-link" href="#">购买</a>&ndash;&gt;-->
        <!--                &lt;!&ndash;                <div v-show="isDropdownVisible" class="dropdown-menu">&ndash;&gt;-->
        <!--                <el-popover placement="bottom" trigger="hover" width="120">-->
        <!--                    <template #reference>-->
        <!--                        <a class="purchase-link" href="#">购买</a>-->
        <!--                    </template>-->
        <!--                    <a class="dropdown-item" href="#" @click.prevent="gotoRecharge">充值</a>-->
        <!--                    <a class="dropdown-item" href="#" @click.prevent="showRedeemDialog">兑换码</a>-->
        <!--                </el-popover>-->

        <!--            </div>-->

        <!--            <el-popover placement="bottom" trigger="hover" width="120">-->

        <!--                <template #reference>-->
        <!--                    <el-icon class="settings-icon" style="cursor: pointer; font-size: 28px; color: #002f5a">-->
        <!--                        <Setting />-->
        <!--                    </el-icon>-->
        <!--                </template>-->
        <!--                <div class="settings-dropdown-menu">-->
        <!--                    <a class="settings-dropdown-item" href="/change-password">修改密码</a>-->
        <!--                    <a class="settings-dropdown-item" href="#"-->
        <!--                       @click.prevent="handleAuthAction">{{ isLoggedIn ? '登出' : '登录' }}</a>-->
        <!--                </div>-->

        <!--            </el-popover>-->

        <!--            <el-avatar class="avatar-icon" size="30" @click="onAvatarClick">-->
        <!--                <el-icon style="font-size: 25px">-->
        <!--                    <UserFilled />-->
        <!--                </el-icon>-->
        <!--            </el-avatar>-->
        <!--        </div>-->

        <RedeemDialog
            :visible="isRedeemDialogVisible"
            @update:visible="setRedeemDialogVisible"
        />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import RedeemDialog from '@/components/Redeem.vue'
import { UserFilled } from '@element-plus/icons-vue'

const router = useRouter()
const isDropdownVisible = ref(false)
const isRedeemDialogVisible = ref(false)
const isSettingsDropdownVisible = ref(false)
const isLoggedIn = ref(false)

// 检查 token 是否存在
const checkAuthStatus = () => {
    isLoggedIn.value = !!localStorage.getItem('token')
}

// 执行登录或登出操作
const handleAuthAction = () => {
    if (isLoggedIn.value) {
        // 执行登出操作
        localStorage.removeItem('token')
        isLoggedIn.value = false
        // 可选：刷新页面或执行其他逻辑
        // 刷新页面
        window.location.reload()
    } else {
        // 跳转到登录页面
        router.push('/login').then(() => {
            window.location.reload()
        })
    }
}

function login() {
    router.push('/login').then(() => {
        window.location.reload()
    })
}

function logout() {
    localStorage.removeItem('token')
    isLoggedIn.value = false
    router.push('/').then(() => {
        window.location.reload()
    })
}

function checkLogin() {
    const token = localStorage.getItem('token')
    if (token) {
        isLoggedIn.value = true
    }
}

const showDropdown = () => {
    isDropdownVisible.value = true
}

const hideDropdown = () => {
    isDropdownVisible.value = false
}

const gotoUpdatePassword = () => {
    router.push('/change-password').then(() => {
        window.location.reload()
    })
}

const gotoRecharge = () => {
    router.push('/recharge').then(() => {
        window.location.reload()
    })
    // alertService.showInfo('充值功能暂不可用，请使用兑换码购买额度')
}


const gotoHome = () => {
    router.push('/').then(() => {
        window.location.reload()
    })
}

const showRedeemDialog = () => {
    isRedeemDialogVisible.value = true
}

const setRedeemDialogVisible = (visible) => {
    isRedeemDialogVisible.value = visible
}

const handleRedeem = (redeemCode) => {
    // 处理兑换逻辑，例如调用 API
}

const onAvatarClick = () => {
    router.push('/user').then(() => {
        window.location.reload()
    })
}

const gotoUser = () => {
    router.push('/user').then(() => {
        window.location.reload()
    })
}

onMounted(() => {
    checkLogin()
})

</script>

<style lang="scss" scoped>
.nav-container {
    border-bottom: 2px solid #002f5a;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-nav {
        display: flex;
        flex-direction: row;

        .logo {
            .logo-link {
                cursor: pointer; /* 默认为手形指针 */
            }
        }

        .head-nav-list {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin: 0;

            .head-nav-detail {
                display: block;
                padding: 10px 20px;
                color: #002f5a;
                text-decoration: none;
                white-space: nowrap;
                cursor: pointer; /* 默认为手形指针 */
            }

            :hover {
                color: #1a8cff;
            }

        }
    }

    .select-login {
        .select-login-btn {
            background-color: #032a50;
            color: #fff;
        }
    }

    .login-avatar-icon {
        cursor: pointer; /* 默认为手形指针 */
    }
}

.el-popper {


    .login-list {
        .login-detail {
            cursor: pointer; /* 默认为手形指针 */
            font-size: 16px;
            padding: 10px 20px;
            //font-weight: bold;
        }
    }
}

.el-popper .login-list .login-detail:hover {
    background-color: #1a8cff;
    color: #fff;
    border-radius: 15px;
}

.right-section {
    display: flex;
    align-items: center;
    gap: 20px; /* 使用gap来控制子元素间隔 */

    .purchase-container {
        position: relative;
    }

    .purchase-link {
        display: inline;
        color: #002f5a;
        text-decoration: none;

        :hover {
            color: #002f5a;
        }
    }
}


//.purchase-link:hover {
//    color: #1a8cff;
//}


.settings-popover {
    padding: 0;
    border: none;
    box-shadow: none;
}

.settings-dropdown-menu {
    display: flex;
    flex-direction: column;
}

.settings-dropdown-item {
    display: block;
    padding: 10px 20px;
    color: #002f5a;
    text-decoration: none;
    white-space: nowrap;
}

.settings-dropdown-item:hover {
    color: #002f5a;
}

.settings-icon {
    cursor: pointer;
}

.avatar-icon {
    cursor: pointer;
}
</style>
