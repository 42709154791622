import axios from 'axios'
import { toQueryString } from '@/utils/queryStringUtils'

// eslint-disable-next-line no-undef
const BASE_URL = process.env.VUE_APP_BASE_URL

// 创建 Axios 实例
const axiosInstance = axios.create({
    baseURL: BASE_URL
    // withCredentials: true // 允许携带Cookiepwd
})

// 添加请求拦截器
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        // config.headers['API_SECRET'] = 'aP8NuNNLDkwbjQR)Rv8Q*pRq';
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)


export const apiService = {
    // 获取验证码
    generateVerifyCode(data) {
        return axiosInstance.post(`/verify_code/generate`, data)
    },
    // 注册
    registerByPhone(data) {
        return axiosInstance.post(`/user/register`, data)
    },
    // 登录
    login(data) {
        return axiosInstance.post(`/user/login`, data)
    },
    // 个人信息
    listUserInfo(requestParams) {
        const queryString = toQueryString(requestParams)
        return axiosInstance.get(`/user/info?${queryString}`)
    },
    // 修改密码
    updatePassword(data) {
        return axiosInstance.put(`/user/password`, data)
    },
    // 找回密码
    retrievePassword(data) {
        return axiosInstance.post(`/user/retrieve`, data)
    },
    // 降重
    weightLossTask(data) {
        return axiosInstance.post(`/weight_loss/generate`, data)
    },
    // 获取内容
    weightLossContent(data) {
        return axiosInstance.post(`/weight_loss/content`, data)
    },
    // 降重历史
    listWeightLossHistory(requestParams) {
        const queryString = toQueryString(requestParams)
        return axiosInstance.get(`/weight_loss/history?${queryString}`)
    },
    // 获取剩余内容
    weightLossTaskRemainContent(data) {
        return axiosInstance.post(`/weight_loss/remain`, data)
    },
    // 兑换码
    activateCoupons(data) {
        return axiosInstance.post(`/code/activate`, data)
    },
    // 根据ID获取降重内容
    listWeightLossContentById(requestParams) {
        // const queryString = toQueryString(requestParams)
        return axiosInstance.get(`/weight_loss/${requestParams}`)
    },
    // 建立支付连接
    createPaymentLink(data) {
        return axiosInstance.post(`/payment/create`, data)
    },
    // 获取产品信息
    listProduct(requestParams) {
        // const queryString = toQueryString(requestParams)
        const id = requestParams['id']
        return axiosInstance.get(`/product/${id}`)
    }
}
