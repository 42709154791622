<template>
    <div class="recharge-center-container">
        <el-card class="recharge-center-container-card">
            <h2> 充值中心 </h2>
            <el-alert
                :closable="false"
                show-icon
                title="1. 充值后货币会立即到账到您的账户，您可以使用货币购买您需要的商品。"
                type="info"
            />
            <el-alert
                :closable="false"
                :title="`2. 充值与人民币的比例为1: ${proportion}，即1元=${proportion}货币，标注选择您要兑换的货币充值面额，最少可输入10个面额。`"
                show-icon
                style="margin-bottom: 20px;"
                type="info"
            />
            <el-row :gutter="20" style="margin-bottom: 20px;">
                <el-col :span="12">
                    <div class="profile-section">
                        <span>充值帐号：{{ phone }}</span>
                    </div>
                </el-col>
                <el-col :span="12" class="text-right">
                    <span>当前余额：{{ balance }}字数</span>
                </el-col>
            </el-row>

            <el-space>

                <span> 充值字数：</span>
                <el-radio-group v-model="amountOfOption" class="recharge-center-recharge" @change="changeRadioGroup">
                    <el-radio v-for="(value,index) in amountOptions" :key="index" :value="value" border
                              class="recharge-center-recharge-option" size="large">{{ value }} 字
                    </el-radio>
                </el-radio-group>

                <!--                <el-input-number v-model="rechargeAmount"-->
                <!--                                 :precision="2" controls-position="right" max="1000000"-->
                <!--                                 min="0" size="large" value-on-clear="其他金额">-->
                <!--                    <template #prefix>-->
                <!--                        <span>¥</span>-->
                <!--                    </template>-->
                <!--                </el-input-number>-->
                <el-input v-model="amountOfInput" maxlength="10" placeholder="自定义其他额度"
                          size="large" style="width: 240px" type="number"
                          @focus="focusInput" @input="input" />
            </el-space>

            <el-row class="recharge-center-payment-method-row" style="margin-top: 20px;">
                <el-text size="large" style="margin-right: 20px"> 支付方式:</el-text>
                <el-radio-group v-model="selectedPaymentMethod" size="large">
                    <el-radio :value="1" border label="支付宝支付" size="large">
                        <img alt="alipay logo" height="100" src="@/assets/alipay-logo.svg" width="80" />
                    </el-radio>
                    <!--                    <el-radio border label="支付宝" size="large">-->
                    <!--                        <el-image src="https://via.placeholder.com/50x20"></el-image>-->
                    <!--                    </el-radio>-->
                </el-radio-group>
            </el-row>

            <div class="amount-due" style="margin-top: 20px;">
                应付金额: <span>{{ rechargeAmount }}元</span>
            </div>
            <el-button style="margin-top: 20px;" type="primary" @click="doRecharge">确认支付</el-button>

        </el-card>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'

const amountOfOption = ref(0)
const amountOfInput = ref(null)
const rechargeAmount = ref(0)
const amountOptions = [100, 200, 1000]
const selectedPaymentMethod = ref(1)
const phone = ref(null)
const balance = ref(0)
const productId = ref(100001)
const subject = ref('自定义金额_100001')
const proportion = ref(0)

function changeRadioGroup() {
    rechargeAmount.value = amountOfOption.value / proportion.value
}

function focusInput() {
    amountOfOption.value = 0
    rechargeAmount.value = 0
}

function input() {
    if (amountOfInput.value > 0 && proportion.value > 0) {
        amountOfInput.value = Math.floor(amountOfInput.value)
        rechargeAmount.value = amountOfInput.value / proportion.value
    } else {
        if (amountOfInput.value === 0) {
            amountOfInput.value = null
        }
    }

}

async function fetchDataUserInfo() {
    const requestParams = {}
    try {
        const response = await apiService.listUserInfo(requestParams)

        phone.value = response.data.userInfo.phone
        balance.value = response.data.userInfo.balance
    } catch (error) {
        if (error.response?.data?.code === 401 || error.response?.data?.business_code === 10001) {
            alertService.showInfo('用户未登录')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

async function fetchProduct() {
    const requestParams = {
        id: productId.value
    }
    try {
        const response = await apiService.listProduct(requestParams)
        proportion.value = response.data.productInfo.proportion
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

async function doRecharge() {
    const requestParams = {
        productId: productId.value,
        totalAmount: String(rechargeAmount.value),
        subject: subject.value
    }
    try {
        const response = await apiService.createPaymentLink(requestParams)
        const url = response.data.paymentUrl
        // 使用window.open方法在新窗口中打开url
        window.open(url, '_blank')
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

onMounted(() => {
    fetchDataUserInfo()
    fetchProduct()
})

</script>

<style>
.recharge-center-container {
    padding: 20px;
    height: 100%;
    //display: flex;

    .recharge-center-container-card {
        width: 90%;
        margin-top: 5%;
        margin-left: 5%;
        align-items: center;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        color: #01284e;
        border-radius: 5px;
    }

    .recharge-center-recharge {
        .recharge-center-recharge-option {
            margin-right: 5px;
        }
    }

    .recharge-center-payment-method-row {
        align-items: center;
    }
}

.profile-section {
    display: flex;
    align-items: center;
}


.amount-due {
    font-size: 18px;
    font-weight: bold;
}
</style>